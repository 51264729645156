import { Grid } from '@fcamna/react-library';
import { useFormContext } from 'react-hook-form';

import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { AEMSubRoute } from '../../../../utils/aem/aemRoute';

export default function SelectVehicleReadOnly() {
  const { getValues } = useFormContext<SubmitApplicationFormData>();

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_SELECT_VEHICLE_READONLY}`}>
      <div className="read-only-wrapper">
        <div>
          <Grid className="gap-y-2">
            <Grid.Item
              s={12}
              m={6}
              l={3}>
              <div className="font-weight-bold font-xsmall my-2">Total Quantity</div>
              <div className="font-xsmall my-2">{getValues(`quantity`)}</div>
            </Grid.Item>
          </Grid>
        </div>
      </div>
    </AEMSubRoute>
  );
}
