import { CheckboxStateValues } from '@fcamna/react-library';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dealer } from '../models/Dealer';
import { BusinessInfoFormData } from '../models/formData/BusinessInfoFormData';
import { GuarantorInfoFormData } from '../models/formData/GuarantorInfoFormData';
import { LabelValue, SubmitApplicationFormData } from '../models/SubmitApplication';
import { ApplicationResponse } from '../services/ApplicationApi';

export interface ConfirmationResponse {
  firstName: string;
  lastName: string;
  dealer: Dealer;
  vehicleData: ApplicationResponse[];
  businessInfo: BusinessInfoFormData;
  hasGuarantor: LabelValue<boolean>;
  hasClocCredit: LabelValue<boolean>;
  guarantorInfo: GuarantorInfoFormData | null;
  confirmationDate: string;
}

interface AppContextProviderProps {
  children: React.ReactNode;
}

const ContextProvider = React.createContext<
  | {
      confirmation: ConfirmationResponse | undefined;
      setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationResponse | undefined>>;
      isProvidedDealer: boolean;
      setIsProvidedDealer: React.Dispatch<React.SetStateAction<boolean>>;
      businessSameAsContact: number;
      setBusinessSameAsContact: React.Dispatch<React.SetStateAction<number>>;
      personalSameAsContact: number;
      setPersonalSameAsContact: React.Dispatch<React.SetStateAction<number>>;
      guarantorSameAsBusiness: number;
      setGuarantorSameAsBusiness: React.Dispatch<React.SetStateAction<number>>;
      updateBusinessSameAsContact: React.RefCallback<void>;
      updatePersonSameAsContact: React.RefCallback<void>;
      updateGuarantorSameAsBusiness: React.RefCallback<void>;
      retry_count: number;
    }
  | undefined
>(undefined);

function AppContextProvider({ children }: Readonly<AppContextProviderProps>) {
  const [confirmation, setConfirmation] = React.useState<ConfirmationResponse>();
  const [isProvidedDealer, setIsProvidedDealer] = React.useState(false);
  const [businessSameAsContact, setBusinessSameAsContact] = React.useState(CheckboxStateValues.UNCHECKED);
  const [personalSameAsContact, setPersonalSameAsContact] = React.useState(CheckboxStateValues.UNCHECKED);
  const [guarantorSameAsBusiness, setGuarantorSameAsBusiness] = React.useState(CheckboxStateValues.UNCHECKED);
  const retry_count = 1;
  const { getValues } = useFormContext<SubmitApplicationFormData>();
  const { businessInfo, applicantInformation, guarantorInfo } = getValues();

  const updateBusinessSameAsContact = useCallback(() => {
    const isBusinessSameAsContact =
      businessInfo != null &&
      businessInfo?.firstName === applicantInformation?.firstName &&
      businessInfo?.lastName === applicantInformation?.lastName &&
      businessInfo?.phoneNumber === applicantInformation?.phoneNumber;

    setBusinessSameAsContact(isBusinessSameAsContact ? CheckboxStateValues.CHECKED : CheckboxStateValues.UNCHECKED);
  }, [applicantInformation?.firstName, applicantInformation?.lastName, applicantInformation?.phoneNumber, businessInfo]);

  const updatePersonSameAsContact = useCallback(() => {
    const isPersonSameAsContact =
      guarantorInfo?.personalInformation != null &&
      guarantorInfo?.personalInformation?.firstName === applicantInformation?.firstName &&
      guarantorInfo?.personalInformation?.lastName === applicantInformation?.lastName &&
      guarantorInfo?.personalInformation?.phoneNumber === applicantInformation?.phoneNumber &&
      guarantorInfo?.personalInformation?.email === applicantInformation?.emailAddress;
    setPersonalSameAsContact(isPersonSameAsContact ? CheckboxStateValues.CHECKED : CheckboxStateValues.UNCHECKED);
  }, [
    applicantInformation?.emailAddress,
    applicantInformation?.firstName,
    applicantInformation?.lastName,
    applicantInformation?.phoneNumber,
    guarantorInfo?.personalInformation
  ]);

  const updateGuarantorSameAsBusiness = useCallback(() => {
    const isGuarantorSameAsBusiness =
      guarantorInfo?.residenceInformation != null &&
      guarantorInfo?.residenceInformation.streetAddress === businessInfo.streetAddress &&
      guarantorInfo?.residenceInformation.city === businessInfo.city &&
      guarantorInfo?.residenceInformation?.state?.value === businessInfo.state.value &&
      guarantorInfo?.residenceInformation.zip === businessInfo.zip;

    setGuarantorSameAsBusiness(isGuarantorSameAsBusiness ? CheckboxStateValues.CHECKED : CheckboxStateValues.UNCHECKED);
  }, [guarantorInfo, businessInfo?.streetAddress, businessInfo?.city, businessInfo?.state?.value, businessInfo?.zip]);

  useEffect(() => {
    updateBusinessSameAsContact();
    updatePersonSameAsContact();
    updateGuarantorSameAsBusiness();
  }, [updateBusinessSameAsContact, updatePersonSameAsContact, updateGuarantorSameAsBusiness, getValues]);

  const value = useMemo(() => {
    return {
      confirmation,
      setConfirmation,
      isProvidedDealer,
      setIsProvidedDealer,
      businessSameAsContact,
      setBusinessSameAsContact,
      personalSameAsContact,
      setPersonalSameAsContact,
      guarantorSameAsBusiness,
      setGuarantorSameAsBusiness,
      updateBusinessSameAsContact,
      updatePersonSameAsContact,
      updateGuarantorSameAsBusiness,
      retry_count
    };
  }, [
    confirmation,
    isProvidedDealer,
    businessSameAsContact,
    personalSameAsContact,
    guarantorSameAsBusiness,
    updateBusinessSameAsContact,
    updatePersonSameAsContact,
    updateGuarantorSameAsBusiness
  ]);

  return <ContextProvider.Provider value={value}>{children}</ContextProvider.Provider>;
}

function useAppContext() {
  const context = React.useContext(ContextProvider);

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
}

export { AppContextProvider, useAppContext };
