import { Card } from '@fcamna/react-library';

import { Page } from '../../../models/FormPage';
import ReviewFooter from '../reviewForm/reviewFooter';
import ReviewHeader from '../reviewForm/reviewHeader';
import PilotDealerVehicleReview from './PilotDealerVehicleReview/PilotDealerVehicleReview';

interface Props {
  handleModify: (step: Page) => void;
  updateStep: Page;
  setUpdatedStep: React.Dispatch<React.SetStateAction<Page>>;
}

function VehicleReview(props: Readonly<Props>) {
  const { handleModify, updateStep, setUpdatedStep } = props;

  return (
    <Card
      cardType="default"
      titleProp={{
        headingLevel: undefined,
        title: '',
        size: undefined
      }}>
      <ReviewHeader
        title={'Credit Applications'}
        handleModify={() => {
          handleModify(Page.VEHICLE);
          setUpdatedStep(Page.VEHICLE);
        }}
        currentUpdatedStep={updateStep === Page.VEHICLE}
      />
      <PilotDealerVehicleReview />
      <ReviewFooter
        handleModify={() => {
          handleModify(Page.VEHICLE);
          setUpdatedStep(Page.VEHICLE);
        }}
      />
    </Card>
  );
}

export default VehicleReview;
