import { EditableButton } from '@fcamna/aem-library';
import { Button, CheckboxStateValues, Grid, Spinner } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { useMutation } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useFormContext, useWatch } from 'react-hook-form';

import { ConfirmationResponse, useAppContext } from '../../../context/appContext';
import useAdobeAnalytics from '../../../hooks/useAdobeAnalaytics';
import useBreakpoint from '../../../hooks/useBreakPoint';
import useVehicleMSRPCalculator from '../../../hooks/useVehicleMSRPCalculator';
import { Page } from '../../../models/FormPage';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import { ApplicationRequest, ApplicationResponse, createApplicationRequest, postApplication } from '../../../services/ApplicationApi';
import { trackAnalayticsOnLoad } from '../../../services/FordProAnalaytics';
import useCocaStore from '../../../store';
import AEMRoute from '../../../utils/aem/aemRoute';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import { getFormattedDate } from '../../../utils/jsUtils';
import { SessionStorageUtils } from '../../../utils/SessionStorageUtils';
import ErrorFallBack from '../../ErrorFallBack';
import BusinessReview from '../businessReview';
import ContactReview from '../contactReview';
import DealerReview from '../dealerReview';
import GuarantorReview from '../guarantorReview';
import styles from '../Review.module.scss';
import TermsAndConditions from '../termsAndConditions';
import { Agreements } from '../termsAndConditions/TermsAndConditions';
import VehicleReview from '../vehicleReview';
import ReviewModify from './reviewModify';

const FORD_PRO_URL = 'fordpro.com';
const isDealerURL = !window.location?.hostname?.includes(FORD_PRO_URL);
const learnMoreCookie = process.env.CLOC_TRACKING_COOKIE_LEARN_MORE;
const applyNowCookie = process.env.CLOC_TRACKING_COOKIE_APPLY_NOW;

const { CaretLeft } = fordProIcons;

export default function ReviewForm() {
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting }
  } = useFormContext<SubmitApplicationFormData>();
  const [page, setPage] = useState<Page>(Page.REVIEW);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAgreed, setIsAgreed] = useState<Agreements>({
    applicant: CheckboxStateValues.UNCHECKED,
    guarantor: CheckboxStateValues.UNCHECKED
  });
  const { setConfirmation } = useAppContext();
  const [updateStep, setUpdateStep] = useState<Page>(Page.REVIEW);
  const [scrollError, setScrollError] = useState<boolean>(false);
  const myRef = useRef<HTMLElement>(null);
  const updateRootPage = useCocaStore((state) => state.updateRootPage);
  const breakPoints = useBreakpoint();
  const isTablet = breakPoints?.s || breakPoints?.xs;
  const formData = getValues();
  const { totalVehicles } = useVehicleMSRPCalculator();

  useWatch({ name: 'downPayment' });

  useAdobeAnalytics(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:review`);

  const handleApplicantCheckChanged = (value: number) => {
    setIsAgreed((prev) => ({ ...prev, applicant: value }));
  };

  const handleGuarantorCheckChanged = (value: number) => {
    setIsAgreed((prev) => ({ ...prev, guarantor: value }));
  };

  const isSubmitEnabled = () => {
    const hasGuarantor = formData.hasGuarantor?.value;
    return (
      isAgreed.applicant === CheckboxStateValues.CHECKED &&
      (!hasGuarantor || isAgreed.guarantor === CheckboxStateValues.CHECKED) &&
      !isSubmitting
    );
  };

  const onFinalSubmit = () => {
    setScrollError(false);
    handleSubmit(onValidCallback, onInvalidCallback)();
  };

  const getApplicationIds = (data: ApplicationResponse[]) => {
    return data.map((response) => response.applicationId).join(' | ');
  };

  const getAdditionalData = (confirmation: ConfirmationResponse) => {
    return {
      page: {
        tool: 'event:lead submitted',
        toolDescriptor: 'lead submitted:credit:coca'
      },
      user: {
        cksVisitId: `financecocafordpro-${confirmation?.vehicleData[0]?.applicationId}`,
        leadType: 'credit:coca'
      }
    };
  };

  const submitApplicationMutation = useMutation({
    mutationFn: postApplication,
    networkMode: 'always',
    onSuccess: (data: ApplicationResponse[]) => {
      const confirmationData: ConfirmationResponse = getConfirmationData(data);
      setConfirmation(confirmationData);
      trackAnalayticsOnLoad(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete`, getAdditionalData(confirmationData));
      resetForm(confirmationData);
      updateRootPage('/confirmation');
    },
    onError: () => {
      setErrorMessage('Sorry, unfortunately our service is unavailable. Please retry at a later time.');
      return Promise.reject(new Error('Something went wrong when submitting the application'));
    }
  });

  const getConfirmationData = (data: ApplicationResponse[]) => {
    return {
      firstName: formData.applicantInformation.firstName,
      lastName: formData.applicantInformation.lastName,
      dealer: formData.dealer.data!,
      vehicleData: data,
      businessInfo: formData.businessInfo,
      hasGuarantor: formData.hasGuarantor,
      hasClocCredit: formData.hasClocCredit,
      guarantorInfo: formData.guarantorInfo ?? null,
      confirmationDate: `Date: ${getFormattedDate()} ${getApplicationIds(data)}`
    };
  };

  const resetFormData = () => {
    reset({
      quantity: 1,
      dealer: isDealerURL ? getValues('dealer') : undefined
    });
    SessionStorageUtils.removeItem('formData');
  };

  const resetForm = (confirmationData: ConfirmationResponse): void => {
    SessionStorageUtils.setParsedItem('confirmation', confirmationData);
    resetFormData();
  };

  const getClocCustomer = () => {
    const cookieValue = document.cookie.split('; ').find((row) => row.startsWith('s_p13_pers='));

    if (cookieValue) {
      const value = cookieValue.split('=')[1];
      return value === learnMoreCookie || value === applyNowCookie;
    }

    return false;
  };

  const onValidCallback = async () => {
    const isClocCustomer = getClocCustomer();
    const requestBody: ApplicationRequest = createApplicationRequest(formData, isClocCustomer);
    await submitApplicationMutation.mutateAsync(requestBody);
  };

  const onInvalidCallback = () => {
    setErrorMessage('Sorry, something went wrong.');
  };

  const executeScroll = () => {
    myRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setScrollError(true);
  };

  const setPageHandler = (step: Page) => {
    setPage(step);
    window.scrollTo({ top: 0 });
  };

  const updateFormData = () => {
    reset(
      {
        ...formData
      },
      { keepValues: false, keepDirty: false }
    );
    SessionStorageUtils.setParsedItem('formData', getValues());
    setPage(Page.REVIEW);
    window.scrollTo({ top: 0 });
  };

  const getTotalVehicles = () => {
    return formData.quantity;
  };

  return (
    <AEMRoute route={`${process.env.AEM_REVIEW}`}>
      {page === Page.REVIEW && (
        <>
          {!isTablet && (
            <div className="mt-7">
              <p className="color-neutral-1000 font-xlarge font-ford-f1-semibold">
                Here&apos;s a summary of your application{getTotalVehicles() > 1 && 's'}
              </p>
              <p className="my-3 font-small color-neutral-800 font-inter">
                {getTotalVehicles() > 1
                  ? `You have selected ${getTotalVehicles()} vehicles. Each vehicle selection will constitute its own separate credit application. 
                  Ensure that all of the information you provided is correct and make any modifications as needed.  The information you provide will be applied to each separate credit application.`
                  : `Ensure that all the information you provided is correct and make any modifications as needed, then
                submit the application by agreeing to the terms and conditions below.`}
              </p>
            </div>
          )}
          <Grid
            gutterWidth={24}
            className={`${styles.reviewGridWrapper} font-inter`}>
            <Grid.Item
              className={styles.reviewItem}
              xl={6}
              l={6}
              m={12}
              s={12}>
              <ErrorBoundary FallbackComponent={ErrorFallBack}>
                <VehicleReview
                  updateStep={updateStep}
                  handleModify={setPageHandler}
                  setUpdatedStep={setUpdateStep}
                />
              </ErrorBoundary>
            </Grid.Item>
            <Grid.Item
              className={styles.reviewItem}
              xl={6}
              l={6}
              m={12}
              s={12}>
              <ErrorBoundary FallbackComponent={ErrorFallBack}>
                <DealerReview
                  handleModify={setPageHandler}
                  dealerInformation={formData.dealer}
                  updateStep={updateStep}
                  setUpdatedStep={setUpdateStep}
                />
              </ErrorBoundary>
            </Grid.Item>
            <Grid.Item
              className={styles.reviewItem}
              xl={12}
              l={12}
              m={12}
              s={12}>
              <ErrorBoundary FallbackComponent={ErrorFallBack}>
                <ContactReview
                  handleModify={setPageHandler}
                  applicantInformation={formData.applicantInformation}
                  updateStep={updateStep}
                  setUpdatedStep={setUpdateStep}
                />
              </ErrorBoundary>
            </Grid.Item>
            <Grid.Item
              className={styles.reviewItem}
              xl={12}
              l={12}
              m={12}
              s={12}>
              <ErrorBoundary FallbackComponent={ErrorFallBack}>
                <BusinessReview
                  handleModify={setPageHandler}
                  businessInfo={formData.businessInfo}
                  updateStep={updateStep}
                  setUpdatedStep={setUpdateStep}
                />
              </ErrorBoundary>
            </Grid.Item>
            <Grid.Item
              className={styles.reviewItem}
              xl={12}
              l={12}
              m={12}
              s={12}>
              <ErrorBoundary FallbackComponent={ErrorFallBack}>
                <GuarantorReview
                  handleModify={setPageHandler}
                  guarantorInformation={formData.guarantorInfo}
                  updateStep={updateStep}
                  setUpdatedStep={setUpdateStep}
                  hasGuarantor={formData.hasGuarantor?.value}
                />
              </ErrorBoundary>
            </Grid.Item>
          </Grid>
          <div
            className="pt-7"
            ref={myRef as React.RefObject<HTMLDivElement>}>
            <TermsAndConditions
              scrollError={scrollError}
              isAgreed={isAgreed}
              isMultiVehicle={totalVehicles > 1}
              handleCheckChanged={{
                applicant: handleApplicantCheckChanged,
                guarantor: handleGuarantorCheckChanged
              }}
              hasGuarantor={formData.hasGuarantor?.value}
            />
          </div>
          <Grid className={styles.submitRow}>
            <Grid.Item
              s={6}
              m={6}
              l={6}
              xl={6}
              className={styles.modifyButton}>
              <Button
                variant="text"
                className={styles.prevBtn}
                leftIcon={<CaretLeft size="s" />}
                onClick={() => {
                  updateRootPage('/apply');
                  window.scrollTo({ top: 0 });
                }}>
                Previous
              </Button>
            </Grid.Item>
            <Grid.Item
              s={6}
              m={6}
              l={6}
              xl={6}>
              <div className="d-flex align-center">
                <EditableButton
                  name="submit-application-button"
                  className="mx-2"
                  onClick={() => {
                    isSubmitEnabled() ? onFinalSubmit() : executeScroll();
                  }}
                />
                {submitApplicationMutation.isLoading && <Spinner className="px-4" />}
              </div>
            </Grid.Item>
            <Grid.Item
              s={6}
              m={6}
              l={6}
              xl={6}>
              <p className={styles.submitError}>{errorMessage}</p>
            </Grid.Item>
          </Grid>
        </>
      )}
      {page !== Page.REVIEW && (
        <div>
          <ReviewModify
            page={page}
            setUpdatedStep={setUpdateStep}
            handleUpdate={updateFormData}
            handleModify={setPageHandler}
          />
        </div>
      )}
    </AEMRoute>
  );
}
