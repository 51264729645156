import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableButton, EditableText } from '@fcamna/aem-library';
import { Accordion, AccordionItem, AccordionPanel, Divider, Image, Spinner } from '@fcamna/react-library';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';

import fordBlueScreen from '../../../assets/images/ford-credit-blue-screen.png';
import { ConfirmationResponse } from '../../../context/appContext';
import { storeApplicationPdf } from '../../../services/ApplicationApi';
import { trackAnalayticsOnClick, trackAnalayticsOnClickDownload } from '../../../services/FordProAnalaytics';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import styles from './ECOAAccordion.module.scss';
import { createPdf as createPdfContent } from './ECOAHelper';

interface Props {
  confirmation: ConfirmationResponse | undefined;
}

export default function ECCOAAccordion(props: Readonly<Props>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isAuthorView = AuthoringUtils.isInEditor();
  const businessRef = useRef<HTMLDivElement | null>(null);
  const guarantorRef = useRef<HTMLDivElement | null>(null);
  const { confirmation } = props;
  const [isPdfDownloadLoading, setIsPdfDownloadLoading] = useState<boolean>(false);
  const [isPdfLoaded, setIsPdfLoaded] = useState<boolean>(false);
  const pdfFileName = `${confirmation?.firstName}-${confirmation?.lastName}-${confirmation?.vehicleData[0]?.applicationId}.pdf`;

  useEffect(() => {
    setTimeout(() => {
      storePdf();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  useEffect(() => {
    if (isOpen) {
      const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete:content`;
      trackAnalayticsOnClick(`${tag} action`, `${tag}:accordion expand:ecoa`);
    }
  }, [isOpen]);

  const createpdf = async () => {
    let doc = new jsPDF('p', 'mm', 'a4', true);

    const businessContent = businessRef.current as HTMLElement;
    const guarantorContent = guarantorRef.current as HTMLElement;

    doc = await createPdfContent(businessContent, guarantorContent, doc, confirmation, fordBlueScreen);
    return doc;
  };

  const downloadPdf = async () => {
    setIsPdfDownloadLoading(true);
    try {
      const doc: jsPDF = await createpdf();
      if (doc && confirmation?.firstName) {
        doc.save(pdfFileName);
        trackAnalayticsOnClickDownload(
          `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete`,
          `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete:download`,
          'ecoa-download'
        );
      }
    } finally {
      setIsPdfDownloadLoading(false);
    }
  };

  const storePdf = async () => {
    const doc: jsPDF = await createpdf();
    setIsPdfLoaded(true);
    if (sessionStorage.getItem('pdfStored') == null) {
      loadPdf(doc);
    }
  };

  const loadPdf = (doc: jsPDF) => {
    if (doc && confirmation?.firstName) {
      const pdfBlob = doc.output('blob');
      const formData = new FormData();

      formData.append('multipartFile', pdfBlob, pdfFileName);
      formData.append('id', confirmation?.vehicleData[0]?.applicationId ?? '');

      storeApplicationPdf(formData).then(() => sessionStorage.setItem('pdfStored', 'true'));
    }
  };

  const getBusinessInfo = () => {
    return (
      <div>
        <div className={styles.fordBlueImg}>
          <Image
            srcProp={{ url: fordBlueScreen, alt: 'protected' }}
            className="pr-2"
            variant="none"
          />
          <div className={styles.fordBlueAddress}>
            <EditableText name="ford-blue-address" />
          </div>
        </div>
        <div
          ref={businessRef}
          className="content-container">
          <div className={`${styles.addressContainer} address-container`}>
            <span style={{ textTransform: 'uppercase' }}>
              {confirmation?.businessInfo?.firstName} {confirmation?.businessInfo?.lastName}
            </span>
            <span>{confirmation?.businessInfo?.streetAddress}</span>
            <span>
              {confirmation?.businessInfo?.city} {confirmation?.businessInfo?.state?.label} {confirmation?.businessInfo?.zip}
            </span>
          </div>
          <p
            className="mb-3"
            style={{ fontSize: '16px' }}>
            Attention: {confirmation?.firstName} {confirmation?.lastName}
          </p>
          <EditableText name="ecoa-content" />
          <div
            className="mt-8"
            style={{ fontSize: '16px' }}>
            {confirmation?.confirmationDate}
          </div>
        </div>
      </div>
    );
  };

  const getGuarantorInfo = () => {
    return (
      <div className="mt-3">
        <div className={styles.fordBlueImg}>
          <Image
            srcProp={{ url: fordBlueScreen, alt: 'protected' }}
            className="pr-2"
            variant="none"
          />
          <div className={styles.fordBlueAddress}>
            <EditableText name="ford-blue-address" />
          </div>
        </div>
        <div
          ref={guarantorRef}
          className="content-container">
          <div className={`${styles.addressContainer} address-container`}>
            <span style={{ textTransform: 'uppercase' }}>
              {confirmation?.guarantorInfo?.personalInformation?.firstName} {confirmation?.guarantorInfo?.personalInformation?.lastName}
            </span>
            <span>{confirmation?.guarantorInfo?.residenceInformation?.streetAddress}</span>
            <span>
              {confirmation?.guarantorInfo?.residenceInformation?.city} {confirmation?.guarantorInfo?.residenceInformation?.state?.label}{' '}
              {confirmation?.guarantorInfo?.residenceInformation?.zip}
            </span>
          </div>
          <p
            className="mb-3"
            style={{ fontSize: '16px' }}>
            Attention: {confirmation?.guarantorInfo?.personalInformation?.firstName}{' '}
            {confirmation?.guarantorInfo?.personalInformation?.lastName}
          </p>
          <EditableText name="ecoa-content" />
          <div
            className="mt-8"
            style={{ fontSize: '16px' }}>
            {confirmation?.confirmationDate}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-4">
      <Accordion dataTestId="ecoaTest">
        <AccordionItem
          disabled={!(isPdfLoaded || isAuthorView)}
          id={'1'}
          title="Click to open/save your Equal Credit Opportunity Act notice"
          boxVariation={true}
          openAccordion={isOpen || isAuthorView}
          setOpenAccordion={(_event, _id, value) => setIsOpen(value)}
          dataTestId="ecoaTestItem1">
          <AccordionPanel>
            {' '}
            <div>
              <div className={styles.ecocaWrapper}>
                <div>
                  {getBusinessInfo()}
                  {confirmation?.guarantorInfo?.personalInformation && (
                    <>
                      <Divider className="mt-6" /> {getGuarantorInfo()}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.savePdfWrapper}>
                <EditableButton
                  name="ecoca-save-pdf-button"
                  className="mt-3"
                  onClick={downloadPdf}
                />
                {isPdfDownloadLoading && <Spinner className={styles.spinner} />}
              </div>
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <div style={{ display: 'none' }}>
        {getBusinessInfo()}
        {getGuarantorInfo()}
      </div>
    </div>
  );
}
