export const isWindow = () => !!window;

export const getFormattedDate = () => {
  const date = new Date();
  const formattedDate =
    `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()} ` +
    `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ` +
    `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}:${date.getMilliseconds().toString().padStart(2, '0')}`;
  return formattedDate;
};

export const isMobileMedia = () => {
  const mediaQuery = isWindow() && (window.matchMedia('(max-width: 599px)') as any);
  if (mediaQuery.matches) {
    return true;
  }
  return false;
};

export const debounce = (func: (args?: any) => void, timeout = 300) => {
  let timer: any;
  return (args?: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(args);
    }, timeout);
  };
};

export const loadScript = ({
  id,
  url,
  cb,
  async = true,
  defer = true,
  className = '',
  dataProps
}: {
  id: string;
  url: string;
  cb?: (args?: any) => void;
  async?: boolean;
  defer?: boolean;
  className?: string;
  dataProps?: Record<string, string>;
}) => {
  if (isWindow()) {
    const exists = document.getElementById(id);

    if (exists) {
      if (cb) {
        cb();
      }
      return;
    }

    const ele = document.createElement('script');
    ele.type = 'text/javascript';
    ele.src = url;
    ele.id = id;
    ele.async = async;
    ele.defer = defer;
    ele.className = className;

    if (dataProps) {
      Object.keys(dataProps).forEach((k) => ele.setAttribute(k, dataProps[k]));
    }

    ele.onload = () => {
      if (cb) {
        cb();
      }
    };

    document.body.appendChild(ele);
  }
};

export const isValidString = (val: any) => {
  return typeof val === 'string' && val.trim().length >= 1;
};

export const isValidEmail = (val: string) => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/.test(val);
};

export const isValidUSFedTaxID = (val: string) => {
  return /^\d{2}-?\d{7}$/.test(val);
};

export const isValidUSZip = (val: string) => {
  return /^\d{5}(-(\d){4})?$/.test(val);
};

export const isValidPostal = (val: string) => {
  return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(val);
};

export const isValidUSPhone = (val: string) => {
  return /\(\d{3}\) \d{3}-\d{4}/.test(val);
};

export const isValidNumber = (val: string | number) => {
  return typeof val === 'number' && !isNaN(val);
};

export const getAEMValue = (
  key: string,
  parsedModel: any,
  keyType: string,
  removeTags: boolean = false,
  removeKeywords: Array<string> = []
) => {
  if (!parsedModel) {
    return '';
  }
  const tags = ['<h1>', '</h1>', '<h2>', '</h2>', '<h3>', '</h3>', '<h4>', '</h4>', '<h5>', '</h5>', '<h6>', '</h6>'];
  if (!parsedModel[key]) {
    return '';
  }
  let value = parsedModel[key][keyType];
  removeKeywords.forEach((keyword) => {
    value = value.replace(keyword, '');
  });
  if (removeTags) {
    tags.forEach((t) => {
      value = value.replace(t, '');
    });
  }
  return value;
};

export const validateFileSize = (files: File[], maxTotalSize: number) => {
  let currTotalSize = 0;
  for (const file of files) {
    currTotalSize += file.size;
  }
  return currTotalSize <= maxTotalSize * 1024 * 1024;
};

export function checkFormObjectValidity(obj: Record<string, any>) {
  // If obj is null, return false
  if (obj === null) {
    return false;
  }

  // If obj is not an object, return false
  if (typeof obj !== 'object') {
    return true;
  }

  // If obj is an array, iterate through each element
  if (Array.isArray(obj)) {
    for (const item of obj) {
      // If the element is not valid, return false
      if (!checkFormObjectValidity(item)) {
        return false;
      }
    }
  }

  // Iterate through each key-value pair in obj
  for (const key in obj) {
    // If the value is null or not valid, return false
    if (obj[key] === null || !checkFormObjectValidity(obj[key])) {
      return false;
    }
  }

  // If all keys and nested keys are valid, return true
  return true;
}

export const getCurrentDate = () => {
  const date = new Date();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${mm}/${dd}/${date.getFullYear()}`;
};

export const setLocalStorage = (key: string, value: string) => {
  if (isWindow()) {
    localStorage.setItem(key, value);
  }
};

export const getLocalStorage = (key: string) => {
  if (isWindow()) {
    return localStorage.getItem(key);
  }
};

export const getSessionStorage = (key: string) => {
  if (isWindow()) {
    return sessionStorage.getItem(key);
  }
};

export const removeSymbols = (str: string): string => {
  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  if (str.length === 0) {
    throw new Error('Input string is empty');
  }

  return str.replace(/[^0-9.]+/g, '');
};

export const toNumber = (str: string): number => {
  const num: number = Number(str);

  if (!isValidNumber(num)) {
    throw new Error('Invalid number format');
  }

  return num;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
};

export const getAEMPath = (key: string, path?: string) => {
  const paths: Record<string, string> = {
    home: process.env.AEM_HOME_PAGE as string,
    apply: process.env.AEM_APPLY_PAGE as string,
    review: process.env.AEM_REVIEW as string,
    confirmation: process.env.AEM_CONFIRMATION as string,
    selectVehicle: process.env.AEM_SELECT_VEHICLE as string,
    dealer: process.env.AEM_DEALER as string,
    contactInformation: process.env.AEM_CONTACT_INFORMATION as string,
    financeInformation: process.env.AEM_FINANCE_INFORMATION as string,
    businessInformation: process.env.AEM_BUSINESS_INFORMATION as string,
    guarantorPersonalInformation: process.env.AEM_GUARANTOR_PERSONAL_INFORMATION as string,
    guarantorResidenceInformation: process.env.AEM_GUARANTOR_RESIDENCE_INFORMATION as string,
    guarantorEmployeeInformation: process.env.AEM_GUARANTOR_EMPLOYMENT_INFORMATION as string
  };

  if (path) {
    return path.replace('/-([a-z])/g', (_match, letter) => letter.toUpperCase());
  }

  return paths[key];
};

export const generateRandomHex = (bytesLength: number) => {
  const array = new Uint8Array(bytesLength);
  window.crypto.getRandomValues(array);
  return Array.from(array)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};

export const getDomain = (url: string) => {
  const parsedUrl = new URL(url);
  let domain = parsedUrl.hostname;
  if (domain.startsWith('www.')) {
    domain = domain.slice(4);
  }
  return domain;
};

export const getBaseDomain = (url: string) => {
  const domain = getDomain(url);
  const parts = domain.split('.');
  const baseDomain = parts[0] === 'www' ? parts[1] : parts[0];
  return baseDomain;
};

export const getFullUrl = (url: string) => {
  const parsedUrl = new URL(url);
  return parsedUrl.host + parsedUrl.pathname;
};
