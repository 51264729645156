import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableInLineAlert, EditableText } from '@fcamna/aem-library';
import { DecrementStepper, IncrementSelector, IncrementStepper, NumberInputField } from '@fcamna/react-library';
import { useFormContext, useWatch } from 'react-hook-form';

import { QUANTITY_LIMITS } from '../../../../constants/vehicle.constant';
import styles from '../SelectVehicle.module.scss';

export default function ExpressVehicle() {
  const { setValue } = useFormContext();
  const quantity = useWatch({ name: 'quantity' }) ?? 1;
  const isMaxQuantityReached = quantity >= QUANTITY_LIMITS.MAX;
  const isAuthorView = AuthoringUtils.isInEditor();

  const handleQuantityChange = (value: number) => {
    setValue('quantity', value);
  };

  return (
    <div>
      <EditableText name="vehicle-purchase-count-text" />
      <div className="my-6">
        <IncrementSelector
          value={quantity}
          labelProp={{ label: 'Total Quantity' }}
          onChange={handleQuantityChange}>
          <DecrementStepper isDisabled={quantity == QUANTITY_LIMITS.MIN} />
          <NumberInputField />
          <IncrementStepper isDisabled={isMaxQuantityReached} />
        </IncrementSelector>
      </div>
      {(isMaxQuantityReached || isAuthorView) && (
        <div className="inline-alert-no-body">
          <EditableInLineAlert
            name="express-max-vehicle-text"
            className={styles.maxQuantityAlert}
          />
        </div>
      )}
    </div>
  );
}
