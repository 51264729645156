import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableButton, EditableText } from '@fcamna/aem-library';
import { Button, Card, Divider, Grid, Image, Link } from '@fcamna/react-library';
import { useEffect } from 'react';

import clocBanner from '../assets/images/cloc-banner.jpg';
import protectedSvg from '../assets/images/info-protected.svg';
import { createAddressParams } from '../components/apply/dealer/DealerCard/DealerCard';
import styles from '../components/confirmation/Confirmation.module.scss';
import ECCOAAccordion from '../components/confirmation/ecoaAccordion';
import Feedback from '../components/confirmation/Feedback/Feedback';
import { useAppContext } from '../context/appContext';
import { ApplicationResponse } from '../services/ApplicationApi';
import { trackAnalayticsOnClickEmail, trackAnalayticsOnExit } from '../services/FordProAnalaytics';
import useCocaStore from '../store';
import AEMRoute from '../utils/aem/aemRoute';
import { CLOC_URL, FORD_PRO_ADOBE_PAGE_NAME_PREFIX, GOGGLE_MAPS_URL } from '../utils/constants';
import { SessionStorageUtils } from '../utils/SessionStorageUtils';

export default function Confirmation() {
  const updateRootPage = useCocaStore((state) => state.updateRootPage);
  const { confirmation, setConfirmation } = useAppContext();
  const CITY_STATE_ZIP = `${confirmation?.dealer.address?.city}, ${confirmation?.dealer.address?.state} ${confirmation?.dealer.address?.postalCode}`;
  const googleMapUrl = GOGGLE_MAPS_URL + createAddressParams(confirmation?.dealer.address);
  const isAuthorView = AuthoringUtils.isInEditor();

  useEffect(() => {
    if (!confirmation) {
      const confirmationData = SessionStorageUtils.getParseItem('confirmation');
      confirmationData && setConfirmation(confirmationData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackClick = () => {
    const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete`;
    trackAnalayticsOnClickEmail(tag, `${tag}:content action`, `${tag}:content:click to call`);
  };

  const trackOnExit = () => {
    const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete`;
    trackAnalayticsOnExit(tag, googleMapUrl);
    window.open(googleMapUrl);
  };

  const trackOnClocExit = () => {
    const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:complete`;
    trackAnalayticsOnExit(tag, CLOC_URL as string);
    window.open(CLOC_URL);
  };

  const handleApplyRoute = () => {
    SessionStorageUtils.clearSession();
    setConfirmation(undefined);
    updateRootPage('/home');
    window.scrollTo({ top: 0 });
  };

  const getClocContent = () => {
    return (
      <Card
        cardType="default"
        orientation="vertical"
        ratio="16/9"
        className="mt-8"
        titleProp={{
          headingLevel: undefined,
          title: '',
          size: undefined
        }}>
        <Grid gutterWidth={20}>
          <Grid.Item
            xl={4}
            l={5}
            m={12}
            s={12}>
            <div className={styles.clocBannerImage}>
              <Image
                srcProp={{ url: clocBanner, alt: 'cloc-banner' }}
                variant="none"
              />
            </div>
          </Grid.Item>
          <Grid.Item
            xl={8}
            l={7}
            m={12}
            s={12}>
            <div className={styles.clocWrapper}>
              <div>
                <EditableText name="cloc-banner-title" />
                <EditableText name="cloc-banner-description" />
              </div>
              <EditableButton
                name="cloc-apply-btn"
                onClick={trackOnClocExit}
              />
            </div>
          </Grid.Item>
        </Grid>
      </Card>
    );
  };

  const getVehicleTitleContent = (vehicle: ApplicationResponse, index: number) => {
    return (
      <div>
        <div className="d-flex">
          <span className={styles.vehicleIndexIcon}>{index}</span>
          <div>
            <div className={styles.applicationNumberExpressTitle}>
              <EditableText name="application-number-title" />
            </div>
            <p className="mt-2 mb-0 font-xsmall">{vehicle?.applicationId}</p>
          </div>
        </div>
      </div>
    );
  };

  const getVehicleContent = () => {
    return confirmation?.vehicleData.map((vehicle: ApplicationResponse, index: number) => (
      <Grid.Item
        key={vehicle.applicationId}
        xl={4}
        l={4}
        m={6}
        s={12}>
        <Card
          cardType="default"
          orientation="vertical"
          ratio="16/9"
          className={styles.applicationDetails}
          titleProp={{
            headingLevel: 'h6',
            title: '',
            size: 'large'
          }}>
          <Card.BodyContent>{getVehicleTitleContent(vehicle, index + 1)}</Card.BodyContent>
        </Card>
      </Grid.Item>
    ));
  };

  const getDealerContent = () => {
    return (
      <Grid
        gutterWidth={24}
        className={styles.applicationDetailsGrid}>
        <Grid.Item
          xl={4}
          l={4}
          m={6}
          s={12}>
          <Card
            cardType="default"
            orientation="vertical"
            ratio="16/9"
            className={styles.dealerDetails}
            titleProp={{
              headingLevel: 'h6',
              title: confirmation?.dealer?.name ?? '',
              size: 'large'
            }}>
            <Card.BodyContent>
              <div className={styles.dealerWrapper}>
                <div>
                  <p className="font-xsmall m-0">{confirmation?.dealer?.address?.street1}</p>
                  <p className="font-xsmall mb-2 m-0">{CITY_STATE_ZIP}</p>
                </div>
                <div className={styles.dealerAddressWrapper}>
                  <Link
                    to={`tel:${confirmation?.dealer.phone}`}
                    size="small"
                    target="_blank"
                    rel="noopener,noreferrer"
                    disabled={false}
                    onClick={trackClick}>
                    {confirmation?.dealer.phone}
                  </Link>
                </div>
                <div className="d-flex flex-column gap-2 fit-content">
                  <Button
                    onClick={trackOnExit}
                    variant="outline"
                    target="_blank"
                    size="small"
                    rel="noopener,noreferrer"
                    className="my-3 fit-content">
                    View Website
                  </Button>
                </div>
              </div>
            </Card.BodyContent>
          </Card>
        </Grid.Item>
      </Grid>
    );
  };

  return (
    <AEMRoute route={`${process.env.AEM_CONFIRMATION}`}>
      <div className="layout-container">
        <EditableButton
          onClick={handleApplyRoute}
          name="cancel-btn"
          className="cancel-btn"
          directProps={{ icon: 'caretLeft', iconPosition: 'left', size: 'small' }}
        />
        <div className="mt-6">
          <EditableText name="confirmation-title" />
          <div className="divider">
            <Divider />
          </div>
          <EditableText name="trusted-text" />
          <div className="d-flex align-center mt-4 gap-x-1">
            <div className="protected-svg">
              <Image
                srcProp={{ url: protectedSvg, alt: 'protected' }}
                variant="none"
              />
            </div>
            <EditableText name="trusted-icon-text" />
          </div>
          <div className={styles.customerAcknowledgementTitleAlign}>
            <p className={styles.customerAcknowledgementTitle}>Thank you {confirmation?.firstName}!</p>
          </div>
          <div className="mb-8 font-small">
            <p>
              Your dealer has received your information and will reach out to you via your preferred contact method to complete your
              application{confirmation && confirmation?.vehicleData?.length > 1 && 's'}.
            </p>
          </div>
          <div className="divider">
            <Divider />
          </div>
          <div>
            <EditableText name="reference-text" />
            <div className={styles.applicationNumberContainer}>
              <div className="mt-9">
                <EditableText name="vehicle-label-text" />
              </div>
              <Grid
                gutterWidth={24}
                className={styles.applicationDetailsGrid}>
                {getVehicleContent()}
              </Grid>
            </div>
            <div className={styles.applicationNumberContainer}>
              <EditableText name="dealer-label-text" />
              {getDealerContent()}
            </div>
          </div>
          <div>
            <ECCOAAccordion confirmation={confirmation} />
            {(isAuthorView || confirmation?.hasClocCredit?.value === false) && getClocContent()}
            <div>
              <Divider className={'cf-field'} />
            </div>
            <Feedback
              confirmation={confirmation}
              hasGuarantor={false}
            />
          </div>
        </div>
      </div>
    </AEMRoute>
  );
}
