import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

const useVehicleMSRPCalculator = () => {
  const quantity = useWatch({ name: 'quantity' });

  const showCLOCContent = useMemo(() => {
    return quantity >= 6;
  }, [quantity]);

  return { showCLOCContent, totalVehicles: quantity };
};

export default useVehicleMSRPCalculator;
