import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Checkbox, CheckboxStateValues, Divider, Grid } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAppContext } from '../../../context/appContext';
import useAdobeAnalytics from '../../../hooks/useAdobeAnalaytics';
import { EnterpriseType } from '../../../models/DataTypes';
import { LabelValue, SubmitApplicationFormData } from '../../../models/SubmitApplication';
import { AEMSubRoute } from '../../../utils/aem/aemRoute';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import { stateOptions } from '../../../utils/StateData';
import AEMInput from '../../common/AEM/Input/AEMInput';
import CurrencyInput from '../../common/CurrencyInput';
import SelectInput from '../../common/SelectInput';
import VehicleGarageAddressInfo from './VehicleGarageAddressInfo';

export default function BusinessInformation() {
  const { getValues, setValue, trigger, clearErrors } = useFormContext<SubmitApplicationFormData>();
  const { businessSameAsContact, setBusinessSameAsContact } = useAppContext();
  const hasVehicleGarageAddress = useWatch({ name: 'businessInfo.hasVehicleGarageAddress' });
  const isAuthorView = AuthoringUtils.isInEditor();

  useAdobeAnalytics(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:business information`);

  const enterpriseTypeOptions: LabelValue<EnterpriseType>[] = [
    { label: 'Corporation', value: 'CORPORATION' },
    { label: 'LLC', value: 'LLC' },
    { label: 'Proprietor', value: 'PROPRIETOR' },
    { label: 'Partnership', value: 'PARTNERSHIP' }
  ];

  const onEnable = () => {
    setValue('businessInfo.firstName', getValues('applicantInformation.firstName'));
    setValue('businessInfo.lastName', getValues('applicantInformation.lastName'));
    setValue('businessInfo.phoneNumber', getValues('applicantInformation.phoneNumber'));
    setBusinessSameAsContact(CheckboxStateValues.CHECKED);
  };

  const onDisable = () => {
    setValue('businessInfo.firstName', '');
    setValue('businessInfo.lastName', '');
    setValue('businessInfo.phoneNumber', '');
    setBusinessSameAsContact(CheckboxStateValues.UNCHECKED);
  };

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_BUSINESS_INFORMATION}`}>
      <ThemeProvider
        themeName={ThemeName.FORD_PRO}
        mode={Mode.LIGHT_MODE}
        productName="COCA">
        <div>
          <Checkbox
            label="Same as contact information"
            checkboxValue={businessSameAsContact}
            onChange={(_e, value) => {
              if (value === CheckboxStateValues.CHECKED) {
                onEnable();
                clearErrors();
              } else {
                onDisable();
              }
              setBusinessSameAsContact(value);
            }}
          />
          <Grid>
            <Grid.Item
              xl={3}
              l={3}
              m={6}
              s={12}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.firstName'}
                  aemName="business-first-name"
                />
              </div>
            </Grid.Item>
            <Grid.Item
              xl={4}
              l={4}
              m={8}
              s={12}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.lastName'}
                  aemName="business-last-name"
                />
              </div>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item
              xl={3}
              l={3}
              m={6}
              s={12}>
              <div className="cf-field mb-4">
                <AEMInput
                  type="text"
                  name={'businessInfo.phoneNumber'}
                  aemName="business-phone-number"
                  mask="(___) ___-____"
                />
              </div>
            </Grid.Item>
          </Grid>
          <div className="divider">
            <Divider />
          </div>
          <Grid>
            <Grid.Item
              xl={4}
              l={4}
              m={8}
              s={12}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.businessName'}
                  aemName="business-legal-name"
                />
              </div>
            </Grid.Item>
            <Grid.Item
              xl={3}
              l={3}
              m={6}
              s={12}>
              <div className="cf-field">
                <SelectInput
                  name={'businessInfo.enterpriseType'}
                  label={'Enterprise Type'}
                  placeholder="Select..."
                  options={enterpriseTypeOptions}
                  onChange={() => {
                    trigger('businessInfo.enterpriseType');
                  }}
                />
              </div>
            </Grid.Item>
            <Grid.Item
              xl={3}
              l={3}
              m={6}
              s={12}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.yearsInBusiness'}
                  aemName="years-in-business"
                  maxLength={3}
                  mask="___"
                />
              </div>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item
              xl={6}
              l={6}
              m={10}
              s={12}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.streetAddress'}
                  aemName="business-street"
                />
              </div>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item
              xl={4}
              l={4}
              m={8}
              s={12}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.city'}
                  aemName="business-city"
                />
              </div>
            </Grid.Item>
            <Grid.Item
              xl={2}
              l={2}
              m={3}
              s={5}>
              <div className="cf-field">
                <SelectInput
                  name={'businessInfo.state'}
                  label={'State'}
                  placeholder="Select..."
                  options={stateOptions}
                  onChange={() => {
                    trigger('businessInfo.state');
                  }}
                  isDefaultSelect={true}
                />
              </div>
            </Grid.Item>
            <Grid.Item
              xl={2}
              l={3}
              m={6}
              s={7}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.zip'}
                  aemName="business-zip-code"
                  mask="_____"
                />
              </div>
            </Grid.Item>
          </Grid>
          <div>
            <div className="cf-field">
              <Checkbox
                label="Vehicle garaging address is different than business address"
                checkboxValue={hasVehicleGarageAddress}
                onChange={() => {
                  setValue('businessInfo.hasVehicleGarageAddress', !hasVehicleGarageAddress);
                }}
              />
            </div>
            {(hasVehicleGarageAddress || isAuthorView) && <VehicleGarageAddressInfo />}
          </div>
          <Grid>
            <Grid.Item
              xl={4}
              l={4}
              m={8}
              s={12}>
              <div className="cf-field">
                <AEMInput
                  type="text"
                  name={'businessInfo.taxId'}
                  aemName="vehicle-garage-tax-id"
                  mask="__-_______"
                />
              </div>
            </Grid.Item>
            <Grid.Item
              xl={4}
              l={4}
              m={8}
              s={12}>
              <div className="cf-field">
                <CurrencyInput
                  name={'businessInfo.monthlyGrossProfit'}
                  type="text"
                  aemName="vehicle-garage-monthly-gross-profit"
                  infoProp={{
                    infoText:
                      'Alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.',
                    infoType: 'popuptip',
                    popupTipHeader: 'Monthly Gross Profit'
                  }}
                />
              </div>
            </Grid.Item>
          </Grid>
        </div>
      </ThemeProvider>
    </AEMSubRoute>
  );
}
