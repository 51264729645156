import { Card, Link } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';

import { Page } from '../../../models/FormPage';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import { trackAnalayticsOnClickEmail, trackAnalayticsOnExit } from '../../../services/FordProAnalaytics';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX, GOGGLE_MAPS_URL } from '../../../utils/constants';
import { createAddressParams } from '../../apply/dealer/DealerCard/DealerCard';
import ReviewFooter from '../reviewForm/reviewFooter';
import ReviewHeader from '../reviewForm/reviewHeader';
import styles from './DealerReview.module.scss';

const { ShowOnMap } = fordProIcons;

interface Props {
  dealerInformation: SubmitApplicationFormData['dealer'];
  handleModify: (step: Page) => void;
  updateStep: Page;
  setUpdatedStep: React.Dispatch<React.SetStateAction<Page>>;
}

function DealerReview(props: Readonly<Props>) {
  const { dealerInformation, handleModify, updateStep, setUpdatedStep } = props;
  const googleMapUrl = GOGGLE_MAPS_URL + createAddressParams(dealerInformation?.data?.address);
  const CITY_STATE_ZIP = `${dealerInformation?.data?.address?.city}, ${dealerInformation?.data?.address?.state} ${dealerInformation?.data?.address?.postalCode}`;

  const trackCallOrMail = () => {
    const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:review`;
    trackAnalayticsOnClickEmail(tag, `${tag}:content action`, `${tag}:content:click to call`);
  };

  const trackOnExit = () => {
    const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:review`;
    trackAnalayticsOnExit(tag, googleMapUrl);
  };

  return (
    <Card
      cardType="default"
      titleProp={{
        headingLevel: undefined,
        title: '',
        size: undefined
      }}>
      <ReviewHeader
        title="Dealer"
        handleModify={() => {
          handleModify(Page.DEALER);
          setUpdatedStep(Page.DEALER);
        }}
        currentUpdatedStep={updateStep === Page.DEALER}
      />
      <div className={styles.dealerReviewWrapper}>
        <span>
          <div className={styles.dealerTitle}>{dealerInformation?.data?.name}</div>
        </span>
        <div>
          <div className="pt-3 d-flex align-center gap-3">
            <span className="font-small">{dealerInformation?.data?.distanceMi} miles</span> {' | '}
            <span className={styles.linkWrapper}>
              <Link
                to={`tel:${dealerInformation?.data?.phone}`}
                size="small"
                target="_blank"
                rel="noopener,noreferrer"
                disabled={false}
                onClick={trackCallOrMail}>
                {dealerInformation?.data?.phone}
              </Link>
            </span>
          </div>
          <div className={styles.mapWrapper}>
            <ShowOnMap data-testid="show-on-map" size="xl" />
            <a
              className={`${styles.dealerLink} font-small`}
              href={googleMapUrl}
              target={'_blank'}
              rel="noopener noreferrer"
              data-testid="get-direction-link"
              onClick={trackOnExit}>
              <p className="m-0">{dealerInformation?.data?.address?.street1}</p>
              <p className="m-0">{CITY_STATE_ZIP}</p>
            </a>
          </div>
        </div>
      </div>
      <ReviewFooter
        handleModify={() => {
          handleModify(Page.DEALER);
          setUpdatedStep(Page.DEALER);
        }}
      />
    </Card>
  );
}

export default DealerReview;
