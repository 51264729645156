import { AxiosResponse } from 'axios';

import { EmploymentStatus, EmploymentType, EnterpriseType, IncomeInterval, ResidenceType } from '../models/DataTypes';
import { SubmitApplicationFormData } from '../models/SubmitApplication';
import { postWithApiKey } from './AxiosHelper';

const SERVICES_URL = process.env.OCA_SERVICES_URL;

interface VehicleGarageInfo {
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
}

interface ResidenceInfo {
  city: string;
  rentMortgagePaymentAmount: number;
  residenceType: ResidenceType;
  state: string;
  streetAddress: string;
  zip: string;
}

interface PersonalInfo {
  dob: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  ssn: string;
}

interface EmploymentInfo {
  employerName: string;
  employmentStatus: EmploymentStatus;
  employmentType: EmploymentType;
  grossIncome: number;
  incomeInterval: IncomeInterval;
  occupation: string;
}

export interface ApplicationRequest {
  isClocCustomer: boolean;
  quantity: number;
  downPayment: number;
  dealer: {
    id: string;
    name: string;
    address?: {
      city?: string;
      country?: string;
      postalCode?: string;
      state?: string;
      street1?: string;
      street2?: string;
      street3?: string;
    };
  };
  applicantInformation: {
    mailAddr: string;
    firstName: string;
    lastName: string;
    phNumber: string;
  };
  businessInformation: {
    firstName: string;
    lastName: string;
    businessName: string;
    enterpriseType: EnterpriseType;
    yearsInBusiness: number;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    phNumber: string;
    hasVehicleGarageAddress: boolean;
    taxId: string;
    monthlyGrossProfit: number;
    vehicleGarageInformation?: VehicleGarageInfo;
  };
  guarantorInformation?: {
    residenceInformation: ResidenceInfo;
    personalInformation: PersonalInfo;
    employmentInformation: EmploymentInfo;
  };
  isDealerUrl: boolean;
}

export interface ApplicationResponse {
  applicationId: string;
}

export const postApplication = async (request: ApplicationRequest): Promise<ApplicationResponse[]> => {
  const response = await postWithApiKey<
    ApplicationResponse[],
    AxiosResponse<ApplicationResponse[], ApplicationRequest>,
    ApplicationRequest
  >(`${SERVICES_URL}/api/v1/application/submit`, request);
  return response.data;
};

export const createApplicationRequest = (formData: SubmitApplicationFormData, isClocCustomer: boolean): ApplicationRequest => {
  return {
    isClocCustomer: isClocCustomer,
    downPayment: formData.downPayment?.floatValue ?? 0,
    dealer: {
      id: formData.dealer.data?.id ?? '',
      name: formData.dealer.data?.name ?? '',
      address: {
        ...formData.dealer.data?.address
      }
    },
    applicantInformation: {
      mailAddr: formData.applicantInformation.emailAddress?.trim(),
      firstName: formData.applicantInformation.firstName?.trim(),
      lastName: formData.applicantInformation.lastName?.trim(),
      phNumber: formData.applicantInformation.phoneNumber.replace(/\D/g, '')
    },
    businessInformation: {
      firstName: formData.businessInfo.firstName?.trim(),
      lastName: formData.businessInfo.lastName?.trim(),
      businessName: formData.businessInfo.businessName?.trim(),
      yearsInBusiness: formData.businessInfo.yearsInBusiness,
      streetAddress: formData.businessInfo.streetAddress?.trim(),
      city: formData.businessInfo.city?.trim(),
      state: formData.businessInfo?.state?.value ?? '',
      zip: formData.businessInfo.zip,
      enterpriseType: formData.businessInfo.enterpriseType.value,
      phNumber: formData.businessInfo.phoneNumber.replace(/\D/g, ''),
      hasVehicleGarageAddress: formData.businessInfo?.hasVehicleGarageAddress ?? false,
      monthlyGrossProfit: formData.businessInfo.monthlyGrossProfit?.floatValue ?? 0,
      taxId: formData.businessInfo?.taxId.replaceAll('-', '') ?? '',
      ...getVehicleGarageInfo(formData)
    },
    quantity: formData.quantity,
    ...getGuarantorInfo(formData),
    isDealerUrl: formData.isDealerUrl
  };
};

const getVehicleGarageInfo = (formData: SubmitApplicationFormData) => {
  const vehicleGarageAddress = formData.businessInfo.vehicleGarageAddress;
  return (
    formData.businessInfo.hasVehicleGarageAddress && {
      vehicleGarageInformation: {
        streetAddress: vehicleGarageAddress?.streetAddress?.trim() ?? '',
        city: vehicleGarageAddress?.city?.trim() ?? '',
        state: vehicleGarageAddress?.state?.value ?? '',
        zip: vehicleGarageAddress?.zip ?? ''
      }
    }
  );
};

const getGuarantorInfo = (formData: SubmitApplicationFormData) => {
  return (
    formData.hasGuarantor.value && {
      guarantorInformation: {
        residenceInformation: {
          residenceType: formData.guarantorInfo?.residenceInformation?.residenceType.value,
          rentMortgagePaymentAmount: formData.guarantorInfo?.residenceInformation?.rentMortgage?.floatValue ?? 0,
          streetAddress: formData.guarantorInfo?.residenceInformation?.streetAddress?.trim(),
          city: formData.guarantorInfo?.residenceInformation?.city?.trim(),
          state: formData.guarantorInfo?.residenceInformation?.state?.value ?? '',
          zip: formData.guarantorInfo?.residenceInformation?.zip
        },
        personalInformation: {
          firstName: formData.guarantorInfo.personalInformation.firstName?.trim(),
          lastName: formData.guarantorInfo.personalInformation.lastName?.trim(),
          email: formData.guarantorInfo.personalInformation.email?.trim(),
          dob: formData.guarantorInfo.personalInformation?.dob.replace(/(..).(..).(....)/, '$3-$1-$2'),
          phoneNumber: formData.guarantorInfo.personalInformation.phoneNumber.replace(/\D/g, ''),
          ssn: formData.guarantorInfo.personalInformation.ssn.replaceAll('-', '')
        },
        employmentInformation: {
          occupation: formData.guarantorInfo.employmentInformation.occupation,
          employerName: formData.guarantorInfo.employmentInformation.employerName?.trim(),
          employmentStatus: formData.guarantorInfo.employmentInformation.employmentStatus.value,
          employmentType: formData.guarantorInfo.employmentInformation.employmentType.value,
          grossIncome: formData.guarantorInfo.employmentInformation.grossIncome?.floatValue ?? 0,
          monthsOnJob: formData.guarantorInfo.employmentInformation.monthsOnJob,
          incomeInterval: formData.guarantorInfo.employmentInformation.incomeInterval.value
        }
      }
    }
  );
};

export const storeApplicationPdf = async (formData: FormData): Promise<void> => {
  const response = await postWithApiKey(`${SERVICES_URL}/api/v1/application/store-pdf`, formData);
  return response.data;
};
