import { Button } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { useFormContext } from 'react-hook-form';

import { useAppContext } from '../../../../context/appContext';
import { NavigationProps } from '../../../../models/NavigationProps';
import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { SessionStorageUtils } from '../../../../utils/SessionStorageUtils';
import styles from './ProgressionButton.module.scss';

const { CaretUp } = fordProIcons;

export default function ProgressionButtons({ onNextClick, onBackClick, step, totalSteps, activeStep }: Readonly<NavigationProps>) {
  const { trigger, setValue, getValues } = useFormContext<SubmitApplicationFormData>();
  const {
    businessSameAsContact,
    personalSameAsContact,
    guarantorSameAsBusiness,
    updateGuarantorSameAsBusiness,
    updateBusinessSameAsContact,
    updatePersonSameAsContact
  } = useAppContext();

  const handleNextClick = async () => {
    const isValid = await trigger(step === 'vehicles' ? 'quantity' : step);
    if (isValid && onNextClick && (await validateOtherFields())) {
      onNextClick();
      if (step === 'applicantInformation') {
        businessSameAsContact && updateBusinessInformation();
        personalSameAsContact && updatePersonalInformation();
      }
      if (step === 'businessInfo') {
        guarantorSameAsBusiness && updateGuarantorInformation();
      }
      step === 'businessInfo' && updateBusinessSameAsContact();
      if (step === 'guarantorInfo') {
        updatePersonSameAsContact();
        updateGuarantorSameAsBusiness();
      }
      scrollToCurrentStepPosition();
      SessionStorageUtils.setParsedItem('formData', getValues());
    }
  };

  const scrollToCurrentStepPosition = () => {
    const elements = document.querySelectorAll('.progressTrackerVertical__ProgressStepIndicator-apnz1r-2');
    const element = Array.from(elements).find((el) => el?.textContent?.trim() === (activeStep + 1).toString());
    if (element != null) {
      window.scrollTo({
        top: window.scrollY + element?.getBoundingClientRect().top - 100,
        behavior: 'smooth'
      });
    }
  };

  const validateOtherFields = async () => {
    const results = await Promise.all([isClocStepValid(), isGuarantorStepValid()]);
    return results.every(Boolean);
  };

  const isGuarantorStepValid = async () => {
    return step === 'guarantorInfo' ? await trigger('hasGuarantor') : true;
  };

  const isClocStepValid = async () => {
    if (step === 'vehicles') {
      return await trigger('hasClocCredit');
    } else {
      return true;
    }
  };

  const updateBusinessInformation = () => {
    setValue('businessInfo.firstName', getValues('applicantInformation.firstName'));
    setValue('businessInfo.lastName', getValues('applicantInformation.lastName'));
    setValue('businessInfo.phoneNumber', getValues('applicantInformation.phoneNumber'));
  };

  const updatePersonalInformation = () => {
    setValue('guarantorInfo.personalInformation.firstName', getValues('applicantInformation.firstName'));
    setValue('guarantorInfo.personalInformation.lastName', getValues('applicantInformation.lastName'));
    setValue('guarantorInfo.personalInformation.phoneNumber', getValues('applicantInformation.phoneNumber'));
    setValue('guarantorInfo.personalInformation.email', getValues('applicantInformation.emailAddress'));
  };

  const updateGuarantorInformation = () => {
    setValue('guarantorInfo.residenceInformation.streetAddress', getValues('businessInfo.streetAddress'));
    setValue('guarantorInfo.residenceInformation.city', getValues('businessInfo.city'));
    setValue('guarantorInfo.residenceInformation.state', getValues('businessInfo.state'));
    setValue('guarantorInfo.residenceInformation.zip', getValues('businessInfo.zip'));
    updateGuarantorSameAsBusiness();
  };

  const getNextStepBtn = () => {
    return <Button onClick={handleNextClick}>{totalSteps === activeStep ? 'Review & Submit' : 'Next Step'}</Button>;
  };

  return (
    <div>
      <div className={`${styles.buttonWrapper} cf-field`}>
        {onBackClick && (
          <Button
            onClick={onBackClick}
            leftIcon={<CaretUp />}
            variant="text"
            className={styles.prevBtn}>
            Previous
          </Button>
        )}
        {onNextClick && getNextStepBtn()}
      </div>
    </div>
  );
}
