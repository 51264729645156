import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableInLineAlert, EditableText } from '@fcamna/aem-library';
import { Divider, Grid } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import useAdobeAnalytics from '../../../hooks/useAdobeAnalaytics';
import useVehicleMSRPCalculator from '../../../hooks/useVehicleMSRPCalculator';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import { AEMSubRoute } from '../../../utils/aem/aemRoute';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import ButtonGroup, { ButtonNameValue } from '../../common/Buttons/ButtonGroup/ButtonGroup';
import CurrencyInput from '../../common/CurrencyInput';
import ExpressVehicle from './ExpressVehicle';
import styles from './SelectVehicle.module.scss';

export default function SelectVehicle() {
  const isAuthorView = AuthoringUtils.isInEditor();
  const quantity = useWatch({ name: 'quantity' });
  const hasLineOfCredit = useWatch({ name: 'hasClocCredit' });
  const { setValue, trigger, clearErrors } = useFormContext<SubmitApplicationFormData>();
  const { showCLOCContent } = useVehicleMSRPCalculator();

  useAdobeAnalytics(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:select vehicles`);

  useEffect(() => {
    if (showCLOCContent) {
      clearErrors();
    }
  }, [clearErrors, showCLOCContent]);

  const clocOptions: ButtonNameValue<boolean>[] = [
    {
      aemName: 'cloc-yes',
      label: 'Yes',
      value: true
    },
    {
      aemName: 'cloc-no',
      label: 'No',
      value: false
    }
  ];

  const getTotalVehicleContent = () => {
    return (
      <>
        <div>
          <div className={styles.totalQuantityWrapper}>
            <EditableText
              name="total-vehicle-text"
              className="mb-2"
            />
            <span className={styles.vehicleCount}>{quantity ?? 1}</span>
          </div>
          <div className="my-2">
            <EditableText name="total-vehicle-description" />
          </div>
        </div>
        {(showCLOCContent || isAuthorView) && getCLOCContent()}
      </>
    );
  };

  const getCLOCContent = () => {
    return (
      <div>
        <Divider />
        <div className="my-4">
          <EditableText name="cloc-text-description" />
        </div>
        <div className="cf-field">
          <ButtonGroup
            name="hasClocCredit"
            aemName="has-cloc-credit"
            buttonProps={clocOptions}
            onClick={(value) => {
              setValue('hasClocCredit', { label: value.label, value: value.value });
              trigger('hasClocCredit');
            }}
          />
        </div>
        {(hasLineOfCredit?.value === true || isAuthorView) && (
          <div className="inline-alert-no-body">
            <EditableInLineAlert name="line-of-credit-yes" />
          </div>
        )}
        {(hasLineOfCredit?.value === false || isAuthorView) && <EditableInLineAlert name="line-of-credit-no" />}
      </div>
    );
  };

  const getDownPayment = () => {
    return (
      <div>
        <Grid>
          <Grid.Item
            xl={4}
            l={4}
            m={8}
            s={12}>
            <div className="cf-field">
              <CurrencyInput
                type="text"
                aemName="down-payment"
                name={'downPayment'}
                placeholder="00.00"
                className={styles.downPaymentText}
              />
            </div>
          </Grid.Item>
        </Grid>
      </div>
    );
  };

  const getPilotDealerDetails = () => {
    return (
      <>
        <div>
          <ExpressVehicle />
        </div>
        {getTotalVehicleContent()}
      </>
    );
  };

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_SELECT_VEHICLE}`}>
      <ThemeProvider
        themeName={ThemeName.FORD_PRO}
        mode={Mode.LIGHT_MODE}
        productName="COCA">
        {getPilotDealerDetails()}
        {getDownPayment()}
      </ThemeProvider>
    </AEMSubRoute>
  );
}
