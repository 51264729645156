import { useEffect, useState } from 'react';

import { trackAnalayticsOnLoad } from '../services/FordProAnalaytics';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from './constants';

interface Attributes {
  [key: string]: string;
}

const FORD_PRO_DOMAIN = 'fordpro.com';
const isFordProDomain = window.location?.hostname?.includes(FORD_PRO_DOMAIN) || window.location?.hostname?.includes('localhost');

const GlobalScripts = () => {
  const [isAnalyticsLoaded, setIsAnalyticsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (isAnalyticsLoaded) {
      trackAnalayticsOnLoad(FORD_PRO_ADOBE_PAGE_NAME_PREFIX);
    }
  }, [isAnalyticsLoaded]);

  useEffect(() => {
    const loadScript = (url: string, attributes?: Attributes[]) => {
      return new Promise((resolve, reject) => {
        const script: any = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        setAttribute(script, attributes);
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await loadScript(process.env.FORD_PRO_WEB_COMPONENT_URL as string);
        setTimeout(async () => {
          if (isFordProDomain) {
            await loadScript(process.env.FORD_PRO_ANALYTICS_URL as string);
            setIsAnalyticsLoaded(true);
            return Promise.resolve();
          }
        }, 300);
        await loadScript(process.env.CCPA_DOMAIN_URL as string, [
          {
            'data-domain-script': process.env.CCPA_DATA_DOMAIN as string,
            type: 'text/javascript'
          }
        ]);
        await loadScript(process.env.MOUSEFLOW_URL as string, [
          {
            className: 'optanon-category-6',
            type: 'text/javascript'
          }
        ]);
      } catch (error) {
        console.error('Error loading script:', error);
      }
    };

    loadScripts();
  }, []);

  return null;
};

const setAttribute = (script: { setAttribute: (arg0: string, arg1: unknown) => void }, attributes: any) => {
  if (attributes) {
    for (const attribute of attributes) {
      Object.entries(attribute).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });
    }
  }
};

export default GlobalScripts;
